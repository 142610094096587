export const users = [
  { key: "userID" },
  { key: "name" },
  { key: "email" },
  { key: "tel" },
  { key: "birthdate" },
  { key: "gender" },
  { key: "postCode" },
  { key: "address" },
  { key: "paymentDate", date: "yyyy-MM-dd HH:mm:ss" },
  { key: "amount" },
  { key: "customer" },
  { key: "updatedAt", defaultSort: "desc", date: "yyyy-MM-dd HH:mm:ss" },
  { key: "createdAt", date: "yyyy-MM-dd HH:mm:ss" },
];
