import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Common from "./commonSlice";

function Error() {
  const error = useSelector((state: any) => state.common.error) as any;
  const dispatch = useDispatch();
  let errors;
  let message;

  if (error && error.errors) {
    errors = error.errors;
    message = errors[0].message;
  }
  function handleClose() {
    dispatch(Common.actions.resetError());
  }

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={3000}
        id="Snackbar_root"
        color="error"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error}
        onClose={() => handleClose()}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        message={message}
      />
    </React.Fragment>
  );
}

export default Error;
