import * as React from "react";
import { Link } from "@reach/router";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText, {
  ListItemTextProps,
} from "@material-ui/core/ListItemText";

type Props = {
  icon?: JSX.Element;
  text: string;
  to: string;
  itemClass?: string;
  itemIconClass?: string;
  itemTextClasses?: ListItemTextProps["classes"];
  onClick: (text: string) => void;
};

const defalutProps = {
  text: "",
  to: "/",
  itemClass: "",
  itemIconClass: "",
  itemTextClass: "",
};

export default function ListItemLink(props: Props) {
  const {
    icon,
    text,
    to,
    itemClass,
    itemIconClass,
    itemTextClasses,
    onClick,
  } = props;

  const CustomLink = (props: any) => <Link to={to} {...props} />;

  return (
    <ListItem
      button
      component={CustomLink}
      onClick={() => onClick(text)}
      className={itemClass}
    >
      {icon && <ListItemIcon className={itemIconClass}>{icon}</ListItemIcon>}
      <ListItemText classes={itemTextClasses}>{text}</ListItemText>
    </ListItem>
  );
}

ListItemLink.defalutProps = defalutProps;
