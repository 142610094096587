import * as React from "react";
import clsx from "clsx";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import * as Router from "@reach/router";
import Divider from "@material-ui/core/Divider";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import HomeIcon from "@material-ui/icons/Home";
// import ListItemText from "@material-ui/core/ListItemText";
// import PeopleIcon from "@material-ui/icons/People";
// import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
// import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import TimerIcon from "@material-ui/icons/Timer";
// import SettingsIcon from "@material-ui/icons/Settings";
// import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import { Omit } from "@material-ui/types";
import ListItemLink from "components/atoms/ListItemLink";
import { useSelector, useDispatch } from "react-redux";
import User from "features/user/userSlice";

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    itemCategory: {
      backgroundColor: "#232f3e",
      boxShadow: "0 -1px 0 #404854 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: "#4fc3f7",
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export interface NavigatorProps
  extends Omit<DrawerProps, "classes">,
    WithStyles<typeof styles> {
  categories: any[];
}

function Navigator(props: NavigatorProps) {
  const { classes, categories, ...other } = props;
  const dispatch = useDispatch();
  const { groups } = useSelector((state: any) => state.user) as any;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          ミハランド管理画面
        </ListItem>

        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            {children.map((item: any) => (
              <ListItemLink
                key={item.id}
                text={item.id}
                icon={item.icon}
                to={`/admin${item.to}`}
                itemClass={clsx(
                  classes.item,
                  item.id === classes.itemActiveItem
                )}
                itemIconClass={classes.itemIcon}
                itemTextClasses={{
                  primary: classes.itemPrimary,
                }}
                onClick={() => {}}
              />
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
