import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import MaterialTable from "material-table";
import * as Router from "@reach/router";
import common from "features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import * as queries from "graphql/queries";
import * as subscriptions from "graphql/subscriptions";
import * as listSettings from "settings/ListSettings";
import { pascalCase } from "change-case";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import pluralize from "pluralize";
import { format } from "date-fns";
export interface ShowProps extends Router.RouteComponentProps {}
type Props = {
  type: string;
  dialog?: boolean;
  variables?: object;
  childList?: string;
  belong?: { key: string; target: string };
  createLimit?: number;
  enableDelete?: (rowData: any) => boolean;
  publish?: boolean;
  preview?: boolean;
  hiddeDelete?: boolean;
  customQuery: string;
};

const defalutProps = {
  dialog: false,
  variables: {},
};

const isSearcble = (type: string) => {
  return false; //Searcbleは反映が遅い
  // return `search${pascalCase(type)}` in queries;
};

function Table(props: Props) {
  const {
    type,
    childList,
    belong,
    variables,
    dialog,
    createLimit,
    enableDelete,
    preview,
    publish,
    hiddeDelete,
    customQuery,
  } = props;
  const dispatch = useDispatch();
  const location = Router.useLocation();
  const { t } = useTranslation();
  const [list, setList] = React.useState([]);
  const [columns, setColumns] = React.useState<any>([]);
  const loading = useSelector((state: any) => state.common.loading) as any;

  let listQuery = isSearcble(type)
    ? `search${pascalCase(type)}`
    : `list${pascalCase(type)}`;

  // const listQuery = `list${pascalCase(type)}`;
  // const subQuery = `onCreate${pluralize.singular(pascalCase(type))}`;

  React.useEffect(() => {
    async function f() {
      dispatch(common.actions.fetchStart());
      try {
        let myList = [];
        let nextToken;

        const res = await API.graphql(
          // @ts-ignore
          graphqlOperation(
            customQuery ? customQuery : queries[listQuery],
            variables
          )
        );
        // @ts-ignore
        const { items } = res.data[listQuery];
        console.log("nextToken", res.data[listQuery].nextToken);
        myList = myList.concat(items);

        nextToken = res.data[listQuery].nextToken;

        while (nextToken) {
          const moreRes = await API.graphql(
            // @ts-ignore
            graphqlOperation(customQuery ? customQuery : queries[listQuery], {
              ...variables,
              nextToken,
            })
          );
          nextToken = moreRes.data[listQuery].nextToken;

          // @ts-ignore
          const { items } = moreRes.data[listQuery];
          console.log("nextToken", moreRes.data[listQuery].nextToken);
          myList = myList.concat(items);
        }

        setList(myList);
        //@ts-ignore
        const colmuns = listSettings[type];
        if (colmuns) {
          const myColums = colmuns.map((item: any) => {
            const base = {
              title: t(item.key),
              field: item.key,
              headerStyle: {
                whiteSpace: "nowrap",
              },
              cellStyle: {
                whiteSpace: "nowrap",
              },
            } as any;

            if ("child_key" in item && item.child_key) {
              base.render = (rowData) =>
                rowData[item.key] && (
                  <span>{rowData[item.key][item.child_key]}</span>
                );
            }

            if ("img" in item) {
              if ("child_key" in item.img && item.img.child_key !== null) {
                base.render = (rowData) =>
                  rowData[item.key] && (
                    <img
                      src={rowData[item.key][item.img.child_key]}
                      style={{ width: "4em" }}
                    />
                  );
              } else {
                base.render = (rowData) =>
                  rowData[item.key] && (
                    <img src={rowData[item.key]} style={{ width: "4em" }} />
                  );
              }
            }
            if ("defaultSort" in item && item.defaultSort) {
              base.defaultSort = item.defaultSort;
            }

            if ("date" in item && item.date) {
              base.type = "date";
              base.render = (rowData) => {
                console.log("rowData", rowData[item.key], item.date);
                return (
                  rowData[item.key] &&
                  format(new Date(rowData[item.key]), item.date)
                );
              };
            }

            return base;
          });
          setColumns(myColums);
        } else {
          if (columns.length === 0) {
            if (items.length > 0) {
              const myColums = Object.keys(items[0]).map((key, index) => {
                return {
                  title: t(key),
                  field: key,
                };
              });
              setColumns(myColums);
            } else {
              setColumns([{ name: "id", field: "id" }]);
            }
          }
        }
        dispatch(common.actions.fetchSuccess());
      } catch (error) {
        console.error("listQuery", error);
        dispatch(common.actions.fetchFailure(error));
      }
    }

    const sub = () => {
      // @ts-ignore
      ["Create", "Update", "Delete"].forEach((method) => {
        const subQuery = `on${method}${pluralize.singular(pascalCase(type))}`;
        // @ts-ignore
        API.graphql(graphqlOperation(subscriptions[subQuery])).subscribe({
          next: (res: any) => {
            async function f() {
              const res = await API.graphql(
                // @ts-ignore
                graphqlOperation(queries[listQuery], variables)
              );
              // @ts-ignore
              const { items } = res.data[listQuery];
              setList(items);
            }
            f();
          },
        });
      });
    };

    const render_time = isSearcble(type) && loading ? 2000 : 0;
    setTimeout(() => {
      f();
      sub();
      if (loading) {
        dispatch(common.actions.fetchSuccess());
      }
    }, render_time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = [];
  if (hiddeDelete !== true) {
    actions.push((rowData) => ({
      icon: "delete",
      tooltip: "削除",
      onClick: async (event) => {
        if (enableDelete) {
          const enable = await enableDelete(rowData);
          if (!enable) {
            event.preventDefault();
            return;
          }
        }
        Router.navigate(`${location.pathname}/${rowData.id}/delete`, {
          state: {
            background: location,
          },
        });
      },
    }));
  }

  if (publish) {
    actions.push((rowData) => ({
      icon: rowData.status !== "publish" ? "visibility_off" : "visibility",
      tooltip: rowData.status !== "publish" ? "公開" : "非公開",
      onClick: (event) =>
        Router.navigate(`${location.pathname}/${rowData.id}/publish`, {
          state: {
            background: location,
            status: rowData.status,
          },
        }),
    }));
  }

  if (preview) {
    actions.push((rowData) => ({
      icon: "preview",
      tooltip: "preview",
      onClick: (event) =>
        Router.navigate(`${location.pathname}/${rowData.id}/preview`, {
          state: {
            background: location,
          },
        }),
    }));

    actions.push((rowData) => ({
      icon: "link",
      tooltip: "link",
      onClick: (event) =>
        Router.navigate(`${location.pathname}/${rowData.id}/edit`, {
          state: {
            background: location,
          },
        }),
    }));
  }

  if (!createLimit || list.length < createLimit) {
    actions.push({
      icon: "add",
      tooltip: "新規",
      isFreeAction: true,
      onClick: () =>
        Router.navigate(`${location.pathname}/_/new`, {
          state: {
            background: dialog ? location : undefined,
          },
        }),
    });
  }
  if (childList) {
    actions.push((rowData) => ({
      icon: "list",
      tooltip: "一覧",
      onClick: (event) =>
        Router.navigate(`${location.pathname}/${rowData.id}/${childList}`),
    }));
  }

  if (belong) {
    actions.push((rowData) => ({
      icon: "info",
      tooltip: "補足",
      onClick: async (event) => {
        const _query = `list${pascalCase(belong.target)}`;
        let filter = {};
        // @ts-ignore
        filter[belong.key] = { eq: rowData.id };
        // @ts-ignore
        const res_belongs = await API.graphql(
          graphqlOperation(queries[_query], {
            filter: filter,
          })
        );
        if (res_belongs.data[_query].items.length > 0) {
          Router.navigate(
            `${location.pathname}/${rowData.id}/${belong.target}/${res_belongs.data[_query].items[0].id}/edit`,
            {
              state: {
                background: location,
              },
            }
          );
        } else {
          Router.navigate(
            `${location.pathname}/${rowData.id}/${belong.target}/_/new`,
            {
              state: {
                background: location,
              },
            }
          );
        }
      },
    }));
  }

  return (
    <>
      {columns.length > 0 && (
        <MaterialTable
          title={t(type)}
          columns={columns}
          data={list}
          onRowClick={(event, rowData: any) => {
            if (rowData?.id) {
              Router.navigate(`${location.pathname}/${rowData.id}/edit`, {
                state: {
                  background: dialog ? location : undefined,
                },
              });
            }
          }}
          actions={actions}
        />
      )}
    </>
  );
}

Table.defaultProps = defalutProps;
export default Table;
