import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Typography, InputLabel } from "@material-ui/core";
import * as mutations from "graphql/mutations";
import * as query from "graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import * as Router from "@reach/router";
import { useDispatch } from "react-redux";
import common from "features/common/commonSlice";
import { useTranslation } from "react-i18next";
import Text from "components/atoms/inputs/Text";
import { pascalCase } from "change-case";
import { useSelector } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import formatISO from "date-fns/formatISO";
import format from "date-fns/format";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      // display: 'flex',
      // flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
);

type FormParams = {
  name: string;
  as: any;
  onChange: any;
};

type Props = {
  formParams: FormParams[];
  dataType: string;
  hiddenCreateInputs?: object;
  afterAction?: (action: string, form: any) => void;
  customValidate?: (action: string, form: any) => void;
};

export default function Form(props: Props) {
  const {
    formParams,
    dataType,
    hiddenCreateInputs,
    afterAction,
    customValidate,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector((state: any) => state.common.loading) as any;
  console.log("Form:", formParams, dataType);
  // const history = useHistory();
  const form = useForm();

  // @see reduxを用いて入力内容の操作

  const modifyInput = (input: any) => {
    let res = Object.assign({}, input);
    formParams.forEach((val: any) => {
      if ("input_name" in val) {
        if (Array.isArray(val.input_name)) {
          const _tmp = input[val.name];
          delete res[val.name];
          val.input_name.forEach((_v, _i) => {
            res[_v] = _tmp[_i];
          });
        } else {
          if (val.input_name !== "") {
            res[val.input_name] = input[val.name];
          }
          delete res[val.name];
        }
      }
    });

    if (input.startDateTime) {
      res.startDateTime = formatISO(new Date(input.startDateTime));
    }
    if (input.endDateTime) {
      res.endDateTime = formatISO(new Date(input.endDateTime));
    }
    if (hiddenCreateInputs) {
      Object.keys(hiddenCreateInputs).forEach((key: any) => {
        res[key] = hiddenCreateInputs[key];
      });
    }
    return res;
  };

  const onSubmit = async (data: any) => {
    await updateData(data);
  };

  const updateData = async (data: any) => {
    console.log("onSubmit:", data);
    dispatch(common.actions.fetchStart());
    async function _create() {
      try {
        if (customValidate) {
          await customValidate(data);
        }
        const res = await API.graphql(
          // @ts-ignore
          graphqlOperation(mutations[`create${pAtion}`], {
            input: modifyInput(data),
          })
        );
        data["id"] = res.data[`create${pAtion}`].id;
        if (afterAction) {
          await afterAction("new", data);
        }
        if (!isSearcble()) {
          dispatch(common.actions.fetchSuccess());
        }
        window.history.back();
      } catch (error) {
        console.error("error:", error);
        dispatch(common.actions.fetchFailure(error));
      }
    }

    async function _delete() {
      try {
        // @todo 数値型を他で行いたい
        await API.graphql(
          // @ts-ignore
          graphqlOperation(mutations[`delete${pAtion}`], {
            input: {
              id: data.id,
            },
          })
        );
        if (afterAction) {
          await afterAction("delete", data);
        }
        if (!isSearcble()) {
          dispatch(common.actions.fetchSuccess());
        }
        window.history.back();
      } catch (error) {
        console.error("error:", error);
        dispatch(common.actions.fetchFailure(error));
      }
    }

    async function _update() {
      try {
        // @todo 数値型を他で行いたい
        // data.expectedVersion = Number(data.expectedVersion);
        // @see 初期作成のownerを引き継ぐ
        data.owner = data.owner;
        if (customValidate) {
          await customValidate(data);
        }
        await API.graphql(
          // @ts-ignore
          graphqlOperation(mutations[`update${pAtion}`], {
            input: modifyInput(data),
          })
        );
        if (afterAction) {
          await afterAction("edit", data);
        }
        if (!isSearcble()) {
          dispatch(common.actions.fetchSuccess());
        }
        window.history.back();
      } catch (error) {
        console.error("error:", error);
        dispatch(common.actions.fetchFailure(error));
      }
    }

    if (action === "new") {
      await _create();
    } else if (action === "delete") {
      await _delete();
    } else {
      await _update();
    }
  };
  const params = Router.useParams();
  const location = Router.useLocation();
  let action = "edit";

  if (params.action === "new") {
    action = "new";
  }

  if (params.action === "delete") {
    action = "delete";
  }

  console.log(params, location);
  const pAtion = pascalCase(dataType);

  React.useEffect(() => {
    async function get() {
      dispatch(common.actions.fetchStart());
      try {
        const value = await API.graphql(
          // @ts-ignore
          graphqlOperation(query[`get${pAtion}`], { id: params.id })
        );
        //@ts-ignore
        const data = value.data[`get${pAtion}`] as any;
        form.setValue("id", data.id);
        // form.setValue("expectedVersion", data.version);
        formParams.forEach((param) => {
          if (data[param.name]) {
            if (param.name === "startDateTime") {
              form.setValue(
                param.name,
                format(new Date(data[param.name]), "yyyy-MM-dd'T'HH:mm:ss")
              );
            } else if (param.name === "endDateTime") {
              form.setValue(
                param.name,
                format(new Date(data[param.name]), "yyyy-MM-dd'T'HH:mm:ss")
              );
            } else {
              form.setValue(param.name, data[param.name]);
            }
          }
          if ("value_column" in param) {
            form.setValue(param.name, data[param.value_column]);
          }
        });
        dispatch(common.actions.fetchSuccess());
      } catch (error) {
        console.error("error:", error);
        dispatch(common.actions.fetchFailure(error));
      }
    }

    if (action !== "new") {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSearcble = (pAction: string) => {
    return false;
    // let listQuery = `search${pAction}`;
    // return listQuery in query;
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Typography variant="h6">{`${t(dataType)} ${t(
              action
            )}`}</Typography>
          </Grid>
          {action !== "new" && <Text name="id" form={form} value={params.id} />} */}
          {formParams.map((params, index) => (
            <Grid item xs={12} key={index}>
              <InputLabel>{t(params.name)}</InputLabel>
              <Controller
                {...params}
                action_type={action}
                error={form.errors[params.name]}
                control={form.control}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              {t(action)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
