import * as React from "react";
import { Provider } from "react-redux";
import store from "./store";
// import Loading from "features/common/Loading";
import Error from "features/common/Error";

type Props = {
  children: React.ReactNode;
};

export default function Cms(props: Props) {
  return (
    <Provider store={store}>
      <Error />
      {props.children}
    </Provider>
  );
}
