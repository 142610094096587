import * as React from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as Router from "@reach/router";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useSelector } from "react-redux";
// import { getContentOwner } from "../../graphql/queries";
import { createBrowserHistory } from "history";

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}
type Props = object;

const defalutProps = {};

export default function ListItemLink(props: Props) {
  const userName = useSelector((state: any) => state.user.userName) as any;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = createBrowserHistory();

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
  };

  const handleMyAccount = async (e) => {
    e.preventDefault();
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const author = await API.graphql(
      graphqlOperation(getContentOwner, { id: cognitoUser.attributes.sub })
    );
    if (author.data.getContentOwner !== null) {
      Router.navigate(
        `/cms/editor/contentOwners/${cognitoUser.attributes.sub}/edit`
      );
    }
  };

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
        {userName}
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClick={handleClose}
        open={open}
        onClose={handleMenu}
      >
        <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
        <MenuItem onClick={handleMyAccount}>アカウント</MenuItem>
      </Menu>
    </>
  );
}

ListItemLink.defalutProps = defalutProps;
