/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      sessionID
      paymentDate
      name
      gender
      email
      phone
      userID
      customer
      birthdate
      postCode
      subscription
      address
      deadline
      createdAt
      updatedAt
      amount
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      sessionID
      paymentDate
      name
      gender
      email
      phone
      userID
      customer
      birthdate
      postCode
      subscription
      address
      deadline
      createdAt
      updatedAt
      amount
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      sessionID
      paymentDate
      name
      gender
      email
      phone
      userID
      customer
      birthdate
      postCode
      subscription
      address
      deadline
      createdAt
      updatedAt
      amount
      owner
    }
  }
`;
