import * as React from "react";
import Table from "components/molecures/Table";
import * as Router from "@reach/router";
import { useSelector } from "react-redux";
export interface ShowProps extends Router.RouteComponentProps {}
export default function Pages(props: ShowProps) {
  const { groups } = useSelector((state: any) => state.user) as any;
  console.log("groups:", groups);

  return <Table type="users" publish={false} preview={false} hiddeDelete />;
}
