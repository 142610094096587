import React from "react";
import {
  createMuiTheme,
  createStyles,
  ThemeProvider,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Navigator from "components/atoms/CmsNavigator";
// import Content from './Content';
import Header from "components/molecures/CmsHeader";
// import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Router from "@reach/router";
import { categories } from "settings/NavigatonSettings";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import User from "features/user/userSlice";

import Amplify from "aws-amplify";
import awsconfig from "aws-exports";
// Amplify.configure({
//   ...awsconfig,
//   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
// });

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">舵社</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = createStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
});

export interface PaperbaseProps
  extends WithStyles<typeof styles>,
    Router.RouteComponentProps {
  children: React.ReactNode;
}
function Paperbase(props: PaperbaseProps) {
  React.useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log("aws_appsync_authenticationType: AMAZON_COGNITO_USER_POOLS");
  }, []);

  const { classes, children } = props;

  // const location = useLocation();
  //@ts-ignore
  let title = "ミハランド管理画面";
  // const { showID } = Router.useParams();

  // console.log("location:", location);

  // //@ts-ignore
  // if (pages[location.pathname]) {
  //   //@ts-ignore
  //   title = pages[location.pathname].title;
  // }
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function getIdentityId() {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentCredentials = await Auth.currentCredentials();
      const username = cognitoUser.username;
      dispatch(User.actions.setUserName(username));

      console.log("cognitoUser:", cognitoUser);
      console.log("currentCredentials:", currentCredentials);
      console.log(
        "group",
        cognitoUser.signInUserSession.accessToken.payload["cognito:groups"]
      );
      console.log("identityId:", currentCredentials.identityId);
      const groups =
        cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];

      if (username !== "admin") {
        window.location.href = `/`;
      }

      // const res = await API.graphql(graphqlOperation(listShows));
      //@ts-ignore
      // const { items } = res.data.listShows;
      dispatch(User.actions.setGroups(groups || []));
      dispatch(User.actions.setUserAttributes(cognitoUser.attributes || null));
      // dispatch(User.actions.setEditableShow(items || []));
      // dispatch(User.actions.setShow(items[0] || null));

      // window.location.href = `/cms/shows/${items[0].id}/teaser`;
    }
    getIdentityId();
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              categories={categories}
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator
              categories={categories}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <Header onDrawerToggle={handleDrawerToggle} title={title} />
          <main className={classes.main}>{children}</main>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Paperbase);
