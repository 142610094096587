import * as React from "react";

import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";

export const categories = [
  {
    id: "管理メニュー",
    children: [
      {
        id: "国民一覧",
        icon: <DnsRoundedIcon />,
        to: "/pages",
        active: false,
      },
    ],
  },
];
