import { TextField } from "@material-ui/core";
import WkEditor from "components/atoms/inputs/WkEditor";
import UploadImage from "components/organisims/UploadImage";
import UploadStorage from "components/organisims/UploadStorage";
import TextClipboard from "components/atoms/TextClipboard";

import React from "react";
import SelectInput from "components/atoms/inputs/Select";
import ComboboxSchema from "components/molecures/ComboboxSchema";
import LimitTags from "components/organisims/LimitArticleTags";
import Checkbox from "components/atoms/inputs/SingleCheckbox";

// カレンダー
const TextFieldDatetime = (
  <TextField
    type="datetime-local"
    InputLabelProps={{
      shrink: true,
    }}
  />
);
// 時間
const TextFieldTime = (
  <TextField
    type="time"
    InputLabelProps={{
      shrink: true,
    }}
  />
);

export const page = [
  {
    name: "id",
    as: (
      <TextField
        InputProps={{
          readOnly: true,
        }}
      />
    ),
  },
  {
    name: "userID",
    as: (
      <TextField
        InputProps={{
          readOnly: true,
        }}
      />
    ),
  },
  {
    name: "name",
    as: <TextField />,
  },
  {
    name: "email",
    as: (
      <TextField
        InputProps={{
          readOnly: true,
        }}
      />
    ),
  },
  {
    name: "tel",
    as: <TextField />,
  },
  {
    name: "postCode",
    as: <TextField />,
  },
  {
    name: "address",
    as: <TextField fullWidth />,
  },
  {
    name: "birthdate",
    as: <TextField fullWidth />,
  },
  {
    name: "gender",
    as: <TextField />,
  },
  {
    name: "amount",
    as: <TextField helperText="課金取り消し時は空白にしてください。" />,
  },
  {
    name: "customer",
    as: <TextField fullWidth />,
  },
  {
    name: "subscription",
    as: <TextField fullWidth />,
  },
  {
    name: "deadline",
    as: <TextField fullWidth />,
  },

  {
    name: "paymentDate",
    as: <TextField fullWidth />,
  },
];
